import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import dana from "../assets/dananew.jpg";
import dana2 from "../assets/dana2.jpg";
import dana3 from "../assets/dana3.jpg";
import dana4 from "../assets/dana4.jpg";
import "./Pagesextra.scss";

function Danapage() {
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="product-detail-page">
      <div className="product-slider">
        <Slider {...settings}>
          <div>
            <img alt="dana" src={dana} />
          </div>
          <div>
            <img alt="dana" src={dana2} />
          </div>
          <div>
            <img alt="dana" src={dana3} />
          </div>
          <div>
            <img alt="dana" src={dana4} />
          </div>
        </Slider>
      </div>
      <div className="product-detail-content">
        <div className="content">
          <div className="section-title">Danalarımız</div>
          <div className="section-description">
            Tüm danalarımız veteriner kontrollü ve en doğal şekilde yetiştirilip
            büyütülmektedir.
          </div>
          <div className="section-description">
            Sizler için kestiğimiz kurbanlık danalarınızı etinizi hiçbir şekilde
            ziyan etmeden parçalayarak size ulaştırıyoruz.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Danapage;

import React, { useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import koc from "../assets/kocnew.jpg";
import koc2 from "../assets/koc2.jpg";
import koc3 from "../assets/koc3.jpg";
import koc4 from "../assets/koc4.jpg";
import koclar from "../assets/koclar.mp4";
import "./Pagesextra.scss";

function Kocpage() {
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="product-detail-page">
      <div className="product-slider">
        <Slider {...settings}>
          <div>
            <video controls muted>
              <source src={koclar} />
            </video>
          </div>
          <div>
            <img alt="koc" src={koc} />
          </div>
          <div>
            <img alt="koc" src={koc2} />
          </div>
          <div>
            <img alt="koc" src={koc3} />
          </div>
          <div>
            <img alt="koc" src={koc4} />
          </div>
        </Slider>
      </div>
      <div className="product-detail-content">
        <div className="content">
          <div className="section-title">Koç</div>
          <div className="section-description">
            Düzenli veteriner kontrolü yapılan kurbanlık, adaklık koçlarımız her
            zaman uzaktan vekaletle alabilirsiniz.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Kocpage;

import React from "react";
import "./Hakkimizda.scss";

const Hakkimizda = () => {
  return (
    <div className="about-us">
      <div className="about-us-background"></div>
      <div className="about-us-content">
        <div className="about-section">
          <div className="about-title">Biz Kimiz?</div>
          <div>
            Biz, İzmir Gümüldür'de köklü bir geleneğe sahip, modern ve
            sürdürülebilir besiciliği benimseyen bir besi çiftlik firması olarak
            sizlere hizmet vermekten gurur duyuyoruz. Misyonumuz, sağlıklı ve
            doğal ürünleri en kaliteli şekilde üreterek sizlere sunmak, çevresel
            sürdürülebilirliği ön planda tutarak doğaya ve topluma katkıda
            bulunmaktır.
          </div>
        </div>
        <div className="about-section">
          <div className="about-title">Vizyonumuz</div>
          <div>
            Çiftliğimizde, en ileri besicilik teknikleri ve yöntemleriyle, çevre
            dostu uygulamalarla bir araya getirilen uzman ekibimizle birlikte,
            kaliteli ve lezzetli ürünlerin üretimini sağlamak, müşterilerimize
            güvenilir ve sağlıklı gıdalar sunmak ve toplumun genel sağlığını
            desteklemektir.
          </div>
        </div>
        <div className="about-section">
          <div className="about-title">Değerlerimiz</div>
          <div>
            <b>Kalite:</b> Ürünlerimizde en yüksek kalite standartlarını
            sağlamak için titizlikle çalışıyoruz.
            <br />
            <b>Doğallık:</b> Besi çiftliğimizde faaliyetlerimizde kimyasal yem
            ve ilaç kullanımını minimumda tutarak doğal ve sağlıklı ürünler
            üretmeye odaklanıyoruz.
            <br />
            <b>Sürdürülebilirlik:</b> Çevreye saygı duyuyor ve sürdürülebilir
            besi uygulamalarını benimseyerek doğal kaynakları koruma amacımıza
            bağlı kalıyoruz.
            <br />
            <b>Müşteri Memnuniyeti:</b> Müşteri odaklı bir anlayışla,
            ihtiyaçlarınıza uygun ürünleri en iyi şekilde sunmayı hedefliyoruz.
          </div>
        </div>
        <div className="about-section">
          <div className="about-title">Ekip</div>
          <div>
            Deneyimli ve uzman bir ekiple çalışarak, besicilik sektöründeki en
            son gelişmeleri takip ediyor, teknolojiyi en etkin şekilde
            kullanarak üretimimizi güçlendiriyoruz. Sizlere sağlıklı ve lezzetli
            ürünler sunabilmek için gece gündüz demeden çalışan ekibimizle
            birlikte, doğanın bize sunduğu nimetleri en doğru şekilde
            değerlendiriyor ve sofralarınıza ulaştırıyoruz. Bizimle tanıştığınız
            için teşekkür eder, sağlıklı ve lezzet dolu günler dileriz!
          </div>
        </div>
      </div>
    </div>
  );
};
export default Hakkimizda;

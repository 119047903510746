import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import keci from "../assets/kecinew.jpg";
import keci2 from "../assets/keci2.jpg";
import keci3 from "../assets/keci3.jpg";
import keci4 from "../assets/keci4.jpg";
import "./Pagesextra.scss";

function Kecipage() {
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="product-detail-page">
      <div className="product-slider">
        <Slider {...settings}>
          <div>
            <img alt="keci" src={keci} />
          </div>
          <div>
            <img alt="keci" src={keci2} />
          </div>
          <div>
            <img alt="keci" src={keci3} />
          </div>
          <div>
            <img alt="keci" src={keci4} />
          </div>
        </Slider>
      </div>
      <div className="product-detail-content">
        <div className="content">
          <div className="section-title">Keçi</div>
          <div className="section-description">
            Veteriner kontrolünde büyüyen, çiftliğimizden siz değerli müşterilere kaliteli ve sağlıklı keçiler sunmaktan gurur duyuyoruz. Her biri özenle yetiştirilmiş ve sağlık kontrollerinden geçmiş keçilerimizle, size en iyi hizmeti sunmayı amaçlıyoruz..
          </div>
        </div>
      </div>
    </div>
  );
}

export default Kecipage;

import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import "./Home.scss";
function Home() {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(function () {
    setIsOpen(true); // Sayfa yüklendiğinde popup'u aç
  }, []);
  return (
    <div className="home-page">
      <div className="home">
        <div className="neon">
          <div className="home-title">ALİ BABA ÇİFTLİĞİ</div>
          <div className="home-content">
            <div className="home-description">
              Helal kesim adaklık-kurbanlık koyun, dana, keçi, erkeç, horoz{" "}
              <br />
              Gümüldür ve Ürkmez içi elden teslim kapıya kadar günlük süt <br />
              Bütün hayvanlarımız ve ürünlerimiz veteriner kontrolünden
              geçmektedir <br />
              Telefon ile vekalet alınır
            </div>
            <div className="wp-container">
              <Link to="/iletisim">
                <button className="wp-button">
                  <div className="btn-icon">
                    <FontAwesomeIcon className="icon" icon={faWhatsapp} />
                  </div>
                  <div className="wp-label">Whatsapp ile sipariş alınır</div>
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="sliding-text-container">
          <div className="sliding-text">
            Adaklık ve kurbanlık kuzu, koç bulunur. Taze günlük elden teslim
            yumurta ve sütümüz vardır!
          </div>
        </div>
      </div>
      <div className="hizmetlerimiz-container">
        <div className="section-title">Hizmetlerimiz</div>
        <div className="hizmetlerimiz-list">
          <div className="list-item">
            <Link to="/kurbanbayramı">
              <div className="item kurbanlık">
                <div className="list-name">2024 Kurban Merasimi</div>
              </div>
            </Link>
          </div>
          <div className="list-item">
            <Link to="/danapage">
              <div className="item dana">
                <div className="list-name">Dana</div>
              </div>
            </Link>
          </div>
          <div className="list-item">
            <Link to="/kecipage">
              <div className="item keci">
                <div className="list-name">Keçi</div>
              </div>
            </Link>
          </div>
          <div className="list-item">
            <Link to="/sutpage">
              <div className="item sut">
                <div className="list-name">Günlük Süt</div>
              </div>
            </Link>
          </div>
          <div className="list-item">
            <Link to="/yumurtapage">
              <div className="item egg">
                <div className="list-name">Tavuk Yumurtaları</div>
              </div>
            </Link>
          </div>
          <div className="list-item">
            <Link to="/horozpage">
              <div className="item horoz">
                <div className="list-name">Horoz</div>
              </div>
            </Link>
          </div>
          <div className="list-item">
            <Link to="/kocpage">
              <div className="item koc">
                <div className="list-name">Koç</div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faSquareInstagram } from "@fortawesome/free-brands-svg-icons";
import "./Iletisim.scss";

function Iletisim() {
  return (
    <div className="contact-page">
      <div className="contact">
        <div className="info">
          <div className="section-title">İletişim Bilgileri</div>
          <div className="info-item">
            <FontAwesomeIcon className="phoneicon" icon={faPhone} /> 0507 634
            5916
          </div>
          <div className="info-item">
            <FontAwesomeIcon className="envelopeicon" icon={faEnvelope} />
            Mail: ciftligialibaba0@gmail.com
          </div>
          <div className="info-item">
            <FontAwesomeIcon
              className="instagramicon"
              icon={faSquareInstagram}
            />
            Instagram:
            <a
              className="instalink"
              href="https://www.instagram.com/gumulduralibabaciftligi/#"
            >
              @gumulduralibabaciftligi
            </a>
          </div>
        </div>
        <div className="pam">
          <iframe
            title="map"
            className="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d44993.65128455624!2d26.993125836868526!3d38.048794973996614!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bbf82cd89dba4f%3A0x404054a9360aeb9d!2zR8O8bcO8bGTDvHIgQ3VtaHVyaXlldCwgTWVyeWVtIEFuYSBDZC4gTm86Mjc0LCAzNTQ4MCBNZW5kZXJlcy_EsHptaXI!5e0!3m2!1str!2str!4v1707597083593!5m2!1str!2str"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Iletisim;

import React from "react";
import Navbar from "./comp/Navbar";
import Footer from "./comp/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Urunler from "./pages/Urunler";
import Iletisim from "./pages/Iletisim";
import Hakkimizda from "./pages/Hakkimizda";
import Danapage from "./pagesextra/Danapage";
import Kecipage from "./pagesextra/Kecipage";
import Yumurtapage from "./pagesextra/Yumurtapage";
import Horozpage from "./pagesextra/Horozpage";
import Kocpage from "./pagesextra/Kocpage";
import Sutpage from "./pagesextra/Sutpage";
import Kurbanlıkpage from "./pagesextra/Kurban";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/urunler" element={<Urunler />} />
          <Route path="/kurbanbayramı" element={<Kurbanlıkpage />} />
          <Route path="/iletisim" element={<Iletisim />} />
          <Route path="/hakkimizda" element={<Hakkimizda />} />
          <Route path="/danapage" element={<Danapage />} />
          <Route path="/sutpage" element={<Sutpage />} />
          <Route path="/yumurtapage" element={<Yumurtapage />} />
          <Route path="/horozpage" element={<Horozpage />} />
          <Route path="/kecipage" element={<Kecipage />} />
          <Route path="/kocpage" element={<Kocpage />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;

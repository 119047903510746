import React, { Fragment } from "react";
import Logo from "../assets/Logo.jpg";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faSquareInstagram } from "@fortawesome/free-brands-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import "./Footer.scss";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-logo-container">
        <img className="footer-logo" alt="logo" src={Logo} />
      </div>
      <Fragment>
        <div className="menu-container">
          <div className="footer-title">Menü</div>
          <Link to="/" className="nav__link">
            Anasayfa
          </Link>
          <Link to="/hakkimizda" className="nav__link">
            Hakkımızda
          </Link>
          <Link to="/urunler" className="nav__link">
            Ürünler
          </Link>
          <Link to="/iletisim" className="nav__link">
            İletişim
          </Link>
        </div>
        <div className="ulasim">
          <div className="footer-title">Bize Ulaşın</div>
          <div>
            <FontAwesomeIcon className="ulasim-icon" icon={faPhone} />
            Tel no: 0507 634 5916
          </div>
          <div>
            <FontAwesomeIcon className="ulasim-icon" icon={faEnvelope} />
            Mail: ciftligialibaba0@gmail.com
          </div>
          <div>
            <FontAwesomeIcon className="ulasim-icon" icon={faSquareInstagram} />
            Instagram: {""}
            <a
              className="instalink"
              href="https://www.instagram.com/gumulduralibabaciftligi/#"
            >
              @gumulduralibabaciftligi
            </a>
          </div>
        </div>
        <div className="konum">
          <div className="footer-title">
            <FontAwesomeIcon icon={faLocationDot} />
            <span className="footer-title">Konumumuz</span>
          </div>
          <div className="address">
            <div>Meryem Ana Caddesi Vatan Mahallesi</div>
            <div>Gümüldür İzmir</div>
          </div>
          <div className="copyright">Copy-Right:Eraycan Uyanık</div>
        </div>
      </Fragment>
    </div>
  );
}

export default Footer;

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bayram1 from "../assets/bayram1.JPG";
import bayram2 from "../assets/bayram2.JPG";
import bayram3 from "../assets/bayram3.JPG";
import bayram4 from "../assets/bayram4.JPG";
import bayram5 from "../assets/bayram5.JPG";
import bayram6 from "../assets/bayram6.JPG";
import bayram7 from "../assets/bayram7.JPG";

import "./Pagesextra.scss";

function Kecipage() {
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="product-detail-page">
      <div className="product-slider">
        <Slider {...settings}>
          <div>
            <img alt="keci" src={bayram1} />
          </div>
          <div>
            <img alt="keci" src={bayram2} />
          </div>
          <div>
            <img alt="keci" src={bayram3} />
          </div>
          <div>
            <img alt="keci" src={bayram4} />
          </div>
          <div>
            <img alt="keci" src={bayram5} />
          </div>
          <div>
            <img alt="keci" src={bayram6} />
          </div>
          <div>
            <img alt="keci" src={bayram7} />
          </div>
        </Slider>
      </div>
      <div className="product-detail-content">
        <div className="content">
          <div className="section-title">2024 Kurban Merasimi</div>
          <div className="section-description">
            2024 yılındaki kurban bayramında her müşterimizi son derece memnun
            ederek, hayırlı bir bayram geçirdik. Bu bayram bizleri tercih eden
            bütün müşterilerimize teşekkür ediyor ve gelecek yıl da bekliyoruz.
            Güler yüz ve müşteri memnuniyeti öncelikli çiftliğimiz her yıl
            olduğu gibi bu yıl da bütün hijyen kurallarına uyarak gerek
            telefondan gerekse canlı vekalat alarak kurban kesimlerimizi
            gerçekleştirdik, dileyen vatandaşlarımızın etlerini onlar için
            parçaladık. Gelecek yılda da kurban bayramında yine hijyenik ve
            müşteri memnuniyetiyle keseceğimiz kurbanlarımızda sizleri de görmek
            isteriz.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Kecipage;

import React, { useState } from "react";
import Logo from "../assets/Logo.jpg";
import { Link } from "react-router-dom";
import "./Navbar.scss";

function Navbar() {
  const [active, setActive] = useState("nav__menu");
  const [icon, setIcon] = useState("nav__toggler");
  const navToggle = () => {
    if (active === "nav__menu") {
      setActive("nav__menu nav__active");
    } else setActive("nav__menu");

    // Icon Toggler
    if (icon === "nav__toggler") {
      setIcon("nav__toggler toggle");
    } else setIcon("nav__toggler");
  };

  window.addEventListener("scroll", function () {
    var nav = this.document.querySelector("nav");
    nav.classList.toggle("sticky", window.scrollY > 0);
  });

  return (
    <nav className="nav">
      <a href="/" className="nav__brand">
        <img alt="logo" src={Logo} />
      </a>
      <ul className={active}>
        <li className="nav__item">
          <Link to="/" className="nav__link">
            Anasayfa
          </Link>
        </li>
        <li className="nav__item">
          <Link to="/hakkimizda" className="nav__link">
            Hakkımızda
          </Link>
        </li>
        <li className="nav__item">
          <Link to="/urunler" className="nav__link">
            Ürünler
          </Link>
        </li>
        <li className="nav__item">
          <Link to="/iletisim" className="nav__link">
            İletişim
          </Link>
        </li>
      </ul>
      <div onClick={navToggle} className={icon}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
    </nav>
  );
}

export default Navbar;

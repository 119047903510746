import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import horoz from "../assets/horoznew.jpg";
import horoz2 from "../assets/horoz2.jpg";
import horoz3 from "../assets/horoz3.jpg";
import horoz4 from "../assets/horoz4.jpg";
import "./Pagesextra.scss";

function Horozpage() {
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="product-detail-page">
      <div className="product-slider">
        <Slider {...settings}>
          <div>
            <img alt="horoz" src={horoz} />
          </div>
          <div>
            <img alt="horoz" src={horoz2} />
          </div>
          <div>
            <img alt="horoz" src={horoz3} />
          </div>
          <div>
            <img alt="horoz" src={horoz4} />
          </div>
        </Slider>
      </div>
      <div className="product-detail-content">
        <div className="content">
          <div className="section-title">Horozlar</div>
          <div className="section-description">
            Adaklık gezen köy horozlarımızı sizler için yüzme işlemini de yapıyoruz.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Horozpage;

import React from "react";
import { Link } from "react-router-dom";
import "./Urunler.scss";

function Urunler() {
  return (
    <div className="products">
      <div className="product-container">
        <div className="list-item">
          <Link to="/kurbanlık">
            <div className="item kurbanlık">
              <div className="list-name">Kurban Bayramı</div>
              <div className="list-desc">2024 Kurban merasini</div>
            </div>
          </Link>
        </div>
        <div className="list-item">
          <Link to="/danapage">
            <div className="item dana">
              <div className="list-name">Dana</div>
              <div className="list-desc">Veteriner kontrollü danalar</div>
            </div>
          </Link>
        </div>
        <div className="list-item">
          <Link to="/Horozpage">
            <div className="item horoz">
              <div className="list-name">Horoz</div>
              <div className="list-desc">Organik gezen horozlar</div>
            </div>
          </Link>
        </div>
        <div className="list-item">
          <Link to="/Kecipage">
            <div className="item keci">
              <div className="list-name">Keçi</div>
              <div className="list-desc">Enfes keçiler</div>
            </div>
          </Link>
        </div>
        <div className="list-item">
          <Link to="/Yumurtapage">
            <div className="item egg">
              <div className="list-name">Gezen Tavuk Yumurtası</div>
              <div className="list-desc">Günlük gezen köy yumurtası</div>
            </div>
          </Link>
        </div>
        <div className="list-item">
          <Link to="/Kocpage">
            <div className="item koc">
              <div className="list-name">Koç</div>
              <div className="list-desc">Kurbanlık, adaklık koçlar</div>
            </div>
          </Link>
        </div>
        <div className="list-item">
          <Link to="/Sutpage">
            <div className="item sut">
              <div className="list-name">Süt</div>
              <div className="list-desc">
                Gümüldür içi elden teslim günlük süt
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Urunler;

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import yumurta from "../assets/yumurtanew.jpg";
import yumurta2 from "../assets/yumurta2.jpg";
import yumurta3 from "../assets/yumurta3.jpg";
import yumurta4 from "../assets/yumurta4.jpg";
import "./Pagesextra.scss";

function Yumurtapage() {
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="product-detail-page">
      <div className="product-slider">
        <Slider {...settings}>
          <div>
            <img alt="yumurta" src={yumurta} />
          </div>
          <div>
            <img alt="yumurta" src={yumurta2} />
          </div>
          <div>
            <img alt="yumurta" src={yumurta3} />
          </div>
          <div>
            <img alt="yumurta" src={yumurta4} />
          </div>
        </Slider>
      </div>
      <div className="product-detail-content">
        <div className="content">
          <div className="section-title">Günlük Yumurtalarımız</div>
          <div className="section-description">
            Kümesten taze organik köy yumurtalarımıza 7/24 ulaşabilirsiniz telefon ile sipariş edebilirsiniz.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Yumurtapage;
